.tr {
  position: relative;
}

.tr:nth-child(even) {
  /* background: #fafafa; */
}

.tr:has(a:hover) {
  /* background: #f6f6f6; */
  background: #7950f21a;
  transition: 0.2s;
}

.trContent {
  /* border: solid 1px red; */
  border-top: none;
  background: var(--gray-50);
}

.active {
  background: var(--gray-50);
}
