.page {
  background: white;
  border: solid 1px var(--gray-300);
  padding: 3em 3em;
  width: 900px;
  margin: 2rem auto;

  font-family: Arial, sans-serif;
  font-size: 12px;
  line-height: 1.6;
  color: #333;
}

/* .page [dr-text] {
  background: var(--teal-200);
}

.page [dr-each] {
  outline: solid 1px var(--teal-400);
} */

.page table {
  width: 100%;
  border-collapse: collapse;
  border: none;
  margin: 2em 0;
  page-break-inside: avoid;
}

.page td,
.page th {
  border: solid 1px var(--gray-300);
  padding: 8px;
}

.page th {
  background-color: #f2f2f2;
  font-weight: bold;
  text-align: left;
}

.page b {
  font-weight: 600;
}

.page .productCode {
  border: solid 1px var(--gray-300);
  border-radius: 50vh;
  display: inline-flex;
  padding: 0 0.75em;
  line-height: 1rem;
}

.page ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.noBorder td,
.noBorder th {
  border: none;
}

.desc {
  color: var(--gray-400);
}

@media print {
  .page {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
  }
}
