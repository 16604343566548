.ddButton {
  /* border: solid 1px red; */
  position: relative;
}

.ddButton button:before {
  content: '';
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--gray-300);
}

.ddButton ul {
  list-style: none;
  padding: 0;
  margin: 0 0.5em;
  position: absolute;
  border: solid 1px var(--gray-300);
  background: white;
  border-radius: 4px;
  /* display: none; */
  z-index: 2;
}

.ddButton li {
  padding: 0.5em 1em;
}

.ddButton label {
  display: flex;
  align-items: center;
  gap: 1em;
  white-space: nowrap;
}

.ddButton ul {
  /* border: solid 1px red; */
  display: block;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
}

.closeBg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
