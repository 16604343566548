.inbox {
  /* list-style: none; */
}

.inbox li {
  margin: 1em 0;
}
.inbox a,
.inbox li {
  display: flex;
  align-items: center;
  gap: 0.5em;
  /* text-decoration: none; */
}
