.layout {
  display: grid;
  grid-template-columns: 350px 1fr;
}

.section {
  padding: 0.5em 1em;
}

.layout b {
  font-weight: 500;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2em;
}

.badge {
  display: inline-flex;
  background: var(--orange-400);
  padding: 0.25em 1em;
  border-radius: 5px;
  color: white;
}

.order h4 {
  margin: 0 1em;
}

.totals {
  list-style: none;
  margin: 0;
  padding: 1em;
  font-size: 0.9em;
}

.totals li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5em 1em;
}

.terms {
  padding: 1rem;
}

.terms table {
  background: white;
  width: 100%;
  border-collapse: collapse;
  border: none;
  font-size: 12px;
}

.terms td,
.terms th {
  border: solid 1px var(--gray-200);
  padding: 0.5em 1em;
}

.terms th {
  background: var(--gray-200);
  /* color: white; */
  text-align: left;
}

.desc {
  color: var(--gray-400);
}
