.layout h4 {
  margin: 1rem;
}

.details {
  background: white;
  margin: 1rem;
  border: solid 1px var(--gray-200);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 14px;
}

.details section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1em 0;
}

.list b {
  font-weight: 500;
  color: var(--teal-700);
}
