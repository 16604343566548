.table {
  font-size: 12px;
  border-collapse: collapse;
  margin: 0.5rem;
}

.table th {
  white-space: nowrap;
}

.table th,
.table td {
  padding: 0.5em 0.75em;
  text-align: left;
  border: solid 1px var(--gray-200);
  white-space: nowrap;
  max-width: 300px;
  min-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.table select {
  border: solid 1px var(--gray-200);
  padding: 0.25em 0.5em;
  border-radius: 3px;
  color: var(--teal-700);
}

.table select option:disabled {
  color: var(--gray-300);
}

.selected {
  background: var(--teal-50);
}

.container {
  overflow: auto;
}
