.table {
  --border-color: #dee2e6;
  --primary: var(--blue-600);
  font-size: 12px;
  border-radius: 5px;
  border: solid 1px var(--border-color);
  margin: 1rem;
  background: white;
}

.table table {
  border-collapse: collapse;

  width: 100%;

  color: var(--gray-600);
}

.thead {
  /* background: #f5f5f5; */
  font-weight: 700;
  border-bottom: solid 1px var(--border-color);
  /* border-top: solid 1px var(--border-color); */
}

.tbody {
  /* border-bottom: solid 1px var(--border-color); */
}

.th {
  text-align: left;
  padding: 1em 0.75em;
}

.header {
  display: flex;
  align-items: center;
  /* justify-content: right; */
  flex-wrap: nowrap;
  gap: 0.5em;
  white-space: nowrap;
}

.table td {
  border-top: solid 1px var(--border-color);
  padding: 0.5em 0.75em;

  a {
    color: #40c057;
    text-decoration: none;
    font-size: 12px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    gap: 1em;
  }

  :global(a.blue) {
    color: var(--blue-500);
  }
}

.sticky {
  position: sticky;
  z-index: 5;
  top: 49px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter {
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  gap: 1em;
  border-bottom: solid 1px var(--border-color);

  --badge-bg: var(--blue-200);
  --badge-text: var(--blue-600);
}

.filter button {
  background: none;
  border: none;
  border-bottom: solid 2px transparent;
  border-radius: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  gap: 1em;
  padding: 1em 1em;
}

.filter button:nth-child(5n + 2) {
  --badge-bg: var(--red-200);
  --badge-text: var(--red-600);
}

.filter button:nth-child(5n + 3) {
  --badge-bg: var(--green-200);
  --badge-text: var(--green-600);
}

.filter button:nth-child(5n + 4) {
  --badge-bg: var(--teal-200);
  --badge-text: var(--teal-500);
}

.filter button:nth-child(5n + 5) {
  --badge-bg: var(--purple-200);
  --badge-text: var(--purple-600);
}

.filter .selected {
  /* background: red; */
  border-bottom: solid 2px var(--badge-text);
}

.filter .selected .total {
  color: var(--badge-bg);
  background: var(--badge-text);
}

.total {
  background: var(--badge-bg);
  color: var(--badge-text);
  padding: 0em 1em;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 8px;
}

.overlay {
  position: absolute;
  inset: 0;
  /* background: rgba(250, 82, 82, 0.7); */
  background: #00000090;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
}

.overlayTitle {
  font-size: 1.5em;
  color: white;
}
