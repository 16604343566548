.section {
  padding: 0.5em 1em;
  /* border: solid 1px red; */
}

.section h4 {
  margin: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actions {
  margin: 0 0.5em;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.subHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5em;
}

.badge {
  display: inline-flex;
  background: var(--orange-400);
  padding: 0.25em 1em;
  border-radius: 5px;
  color: white;
}

.quantity {
  white-space: nowrap;
}

.quantity button {
  padding: 0;
  height: 1.5rem;
  width: 1.5rem;
  font-size: 1.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  color: white;
  border: none;
}

.quantity button:disabled {
  background: var(--gray-200);
}

.quantity b {
  min-width: 1.5em;
  display: inline-flex;
  justify-content: center;
}

.pre {
  white-space: pre;
  font-size: 0.9em;
  color: var(--gray-600);
  padding: 0 0 0 10px;
  border-left: 1px solid rgb(230, 230, 230);
}

.timeline {
  padding: 1em 2em;
}

.modalActions {
  padding: 0 0.5rem;
}
