.list {
  list-style: none;
  margin: 0;
  padding: 0.5em;
  border-bottom: solid 1px var(--teal-500);

  --nav-hover-color: var(--mantine-color-green-6);
  --nav-hover-bg: rgba(64, 192, 87, 0.2);

  --nav-hover-color: var(--teal-900);
  --nav-hover-bg: var(--teal-300);
}

.list a,
.list .tab {
  display: flex;
  align-items: center;
  gap: 1em;
  color: white;
  font-size: 14px;
  text-decoration: none;
  padding: 0.75em 0.75em;
  border-radius: 3px;
}

.list a:hover {
  background: var(--teal-900);
}

.list a:global(.active) {
  background: var(--nav-hover-bg);
  color: var(--nav-hover-color);
}

.children {
  padding: 0;
  margin-left: 1.25em;
  border: none;
}
