.form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.row {
  display: grid;
  align-items: center;
  gap: 2em;
  grid-template-columns: 1fr 1fr;
}

.products {
  list-style: none;
  margin: 0;
  padding: 0;
}

.products li {
  margin: 0.5em 1em;
}
