.button {
  display: flex;
  justify-content: flex-end;
  margin: 0 0.5rem;
}

.step {
  max-width: 600px;
  margin: auto;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
