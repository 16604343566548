.SettingsPage {

  /* Adjust button and text input label colors */
  .mantine-Button-label,
  .mantine-List-itemLabel,
  .mantine-TextInput-label {
    color: #44464c;
    /* A shade of gray */
  }

  /* Ensure text within buttons is also not black */
  .mantine-Button-inner {
    color: #5c5f66;
  }

  /* Adjust text color for accordion controls */
  .mantine-Accordion-control {
    color: #5c5f66;
  }

  /* Adjust text color for all text elements within the Settings component */
  .mantine-Text-root {
    color: #5c5f66;
  }

  /* Ensure placeholder text in inputs is also a shade of gray */
  .mantine-TextInput-input::placeholder {
    color: #868e96;
  }

  /* Adjust the color of icons to match the new gray theme */
  .mantine-Button-icon svg,
  .mantine-Accordion-icon svg {
    color: #5c5f66;
  }
}