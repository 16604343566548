.layout {
  font-size: 14px;
  padding: 0 1rem;
}

.header h3 {
  margin: 0;
}

.subTitle {
  padding: 0.5em 1em;
  font-size: 0.9em;
  color: var(--gray-500);
}

.city {
  display: flex;
  align-items: center;
  gap: 0.75em;
  margin: 1em 0;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;

  background: white;
  border: solid 1px var(--gray-200);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 20px -12px var(--teal-700);
}

.list b {
  font-weight: 500;
  color: var(--teal-700);
}

.list h4 {
  margin: 0;
  padding: 0.5rem 1rem;
  background: var(--teal-700);
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list li {
  padding: 0.5rem 1rem;
  border-top: solid 1px var(--gray-200);
  display: flex;
  align-items: center;
  gap: 0.75em;
  flex-wrap: wrap;
}

.active {
  background: var(--orange-100);
}

.layout .acordionAside {
  margin: 0;
}

.layout :global(.mantine-Accordion-item) {
  border-bottom: 1px solid var(--gray-200);
}

.layout :global(.mantine-Accordion-control) {
  /* background-color: var(--gray-100); */
  background-color: var(--teal-700);
  color: white;
  margin: 1rem 0 0 0;
}

.layout :global(.mantine-Accordion-panel) {
  margin: 0;
  padding: 0;
}

.layout :global(.mantine-Accordion-control:hover) {
  background-color: color-mix(in srgb, var(--teal-700) 80%, var(--teal-900));
  color: white;
}

.layout :global(.mantine-Accordion-chevron) {
  transition: transform 0.3s ease;
}

.layout :global(.mantine-Accordion-item[data-active] .mantine-Accordion-chevron) {
  transform: rotate(180deg);
}

.layout :global(.mantine-Accordion-content) {
  padding: 0;
  background-color: white;
}

.layout :global(.mantine-Accordion-icon) {
  margin-right: 0.5rem;
  color: var(--teal-700);
}
