.content {
  margin: 0 0.5rem;
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  border: solid 1px var(--gray-200);
}

.content h2 {
  text-align: center;
}

.step {
  margin: 0 1rem;
  max-width: 600px;
  margin: auto;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

/* .row {
  display: grid;
  align-items: center;
  gap: 2em;
  grid-template-columns: 1fr 1fr;
} */
