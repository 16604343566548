.dashboard {
  text-align: center;

  h1 {
    font-size: 3em;
    width: 50%;
    margin: 1em auto;
  }

  p {
    color: var(--gray-600);
    width: 50%;
    margin: 1em auto;
  }

  &__form {
    margin-top: 3em;
    display: flex;
    gap: 1em;
    justify-content: center;
  }

  &__actions {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      // gap: 1em;
      align-items: stretch;
    }
  }

  &__input {
    textarea {
      width: 500px;
      height: 100%;
      border-radius: 0.5em;
      border: solid 1px var(--blue-300);
      padding: 1em 1.5em;
      font-size: 1.2em;
      margin-bottom: 1em;

      &:focus-visible {
        outline: none;
      }
    }
  }

  &__metricCard{
    border-radius: 8px;
    border:1px solid #dee2e6;

  }
}
