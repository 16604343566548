.container {
  position: relative;
}

.button {
  display: inline-flex;
  align-items: stretch;
  /* gap: 1em; */
  background: white;
  color: var(--gray-700);
  border-radius: 4px;
  font-size: 12px;
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  border: solid 1px var(--gray-300);
  cursor: pointer;
  overflow: hidden;
}

.label {
  padding: 0.25em 0.75em;
}

.toggle {
  padding: 0.25em 0.75em;
  background: none;
  border-left: solid 1px var(--gray-300);
  margin: 0;
  border-radius: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.button:hover .toggle,
.active .toggle {
  background: var(--gray-100);
}

.active .toggle::before {
  content: '';
  /* background: #00000055; */
  position: fixed;
  inset: 0;
  z-index: 99;
}

.button[disabled],
.button[disabled] .toggle {
  background: var(--gray-100);
  color: var(--gray-400);
  border-color: var(--gray-100);
}

.menu {
  position: absolute;
  z-index: 100;
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 12px;
  border: solid 1px var(--gray-300);
  border-radius: 4px;
  background: white;
  margin: 0.25em 0;
}

.menu li {
  border-top: solid 1px var(--gray-300);
}
.menuItem {
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 0.5em 1em;
  cursor: pointer;
  margin: 0;
  border: none;
  background: none;
  border-radius: none;
  text-decoration: none;
  color: var(--gray-700);
}

.menu li:first-child {
  border: none;
}

.menu li:hover {
  background: var(--gray-100);
}
