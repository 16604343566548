.bar {
  list-style: none;
  margin: 0;
  padding: 1em 0;

  display: flex;

  font-size: 12px;

  --line-color: var(--gray-300);

  gap: 3px;
}

.bar li {
  border-radius: 0.5em;
  background: var(--line-color);
  flex: 1;
  position: relative;
  /* border: solid 1px red; */
  padding: 1em;
  padding-left: 3em;
  white-space: nowrap;
}

.bar li:before {
  content: '';
  height: 35px;
  width: 35px;

  top: 4px;
  right: -12px;

  position: absolute;
  background: var(--line-color);

  border-top: solid 3px white;
  border-right: solid 3px white;

  z-index: 11;
  rotate: 45deg;
  border-radius: 0.5em;
}

.bar li:first-child {
  padding-left: 2em;
}

.bar li:last-child:before {
  content: none;
}

.isDone {
  --line-color: var(--teal-700);
  color: white;
}

.isCurrent {
  color: white;
  --line-color: var(--orange-500);
}
